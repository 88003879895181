import { styled } from 'twin.macro';

import { Flex } from '@ubisend/pulse-components';

const TicketMainPanel = styled(Flex)`
  width: calc(100% - 30rem);
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export default TicketMainPanel;

import tw, { styled } from 'twin.macro';

import { Panel } from '@ubisend/pulse-components';

const TicketSubscriberPanel = styled(Panel)`
  ${tw`hidden h-screen w-full max-w-sm z-10 fixed right-0 overflow-y-auto border-l border-t-0 border-b-0 border-r-0 rounded-none`}
  @media (min-width: 1400px) {
    display: block;
  }
`;

export default TicketSubscriberPanel;

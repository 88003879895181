import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Redirect } from 'react-router-dom';

import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Tag,
  Flex,
  Span,
  Button,
  Heading2,
  useModal,
  ModalBody,
  TabButton,
  ModalFooter,
  ModalHeader,
  Placeholder,
  ModalContent,
  ModalContainer,
  useNotification
} from '@ubisend/pulse-components';
import { PermissionFilter, PermissionsFilter } from '@ubisend/pulse-auth';
import Icon from '@ubisend/pulse-icons';
import {
  getTicket,
  TicketMainPanel,
  SubscriberDetails,
  ForwardTicketButton,
  TicketSubscriberPanel,
  TicketSubscriberDetails
} from '@ubisend/pulse-tickets';

import {
  claimTicket as claimTicketApi,
  updateTicket as updateTicketApi,
  archiveTicket as archiveTicketApi
} from '../../../../api/index';
import { Conversation, Ticket, ResolveButton } from './Components/index';

const showUnclaim = ticket => {
  return ticket.status === 'in_progress' && ticket.is_active;
};

const showForward = ticket => {
  return showUnclaim(ticket);
};

const showResolve = ticket => {
  return ticket.status === 'in_progress';
};

const showDelete = ticket => {
  return ticket.status === 'in_progress';
};

const showClaim = ticket => {
  return ticket.status !== 'in_progress';
};

const LiveChat = ({ match, agent }) => {
  const [tab, setTab] = useState('CONVERSATION');

  const { showSuccess } = useNotification();

  const { showModal, hideModal } = useModal();

  const history = useHistory();

  const queryClient = useQueryClient();

  const ticketQuery = useQuery(`tickets/ubidesk/${match.params.id}`, {
    cacheTime: 0,
    // Use the tab in the query hash so it reruns everytime the tab is changed.
    queryKeyHashFn: () => `tickets/ubidesk/${match.params.id}?tab=${tab}`
  });

  const claimTicket = useMutation(claimTicketApi, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully claimed live chat ticket.');
      queryClient.invalidateQueries('tickets/ubidesk');
      queryClient.invalidateQueries(`tickets/ubidesk/${data.data.id}`);
    }
  });

  const archiveTicket = useMutation(archiveTicketApi, {
    onSuccess: () => {
      showSuccess('Successfully archived live chat ticket.');
      queryClient.invalidateQueries('tickets/ubidesk');
      history.push(`/live-chat`);
    }
  });

  const unClaimTicket = useMutation(updateTicketApi, {
    onSuccess: () => {
      showSuccess('Successfully unclaimed live chat ticket.');
      queryClient.invalidateQueries('tickets/ubidesk');
      history.push(`/live-chat`);
    }
  });

  const handleClaim = () => {
    claimTicket.mutate(match.params.id);
  };

  const handleDelete = () => {
    showModal({
      header: 'Remove ticket',
      body: `Are you sure you want to remove this live chat ticket?`,
      handleConfirm: async () => {
        try {
          await archiveTicket.mutate(ticketQuery.data.data.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleUnclaim = () => {
    unClaimTicket.mutate({
      ...getTicket(ticketQuery.data.data),
      status: 'submitted',
      assignee_id: null
    });
  };

  const handleDeleteAfterSubscriberHasLeft = () => {
    archiveTicket.mutate(ticketQuery.data.data.id);
  };

  const canSeeLiveChat = useMemo(() => {
    if (!ticketQuery.isSuccess) {
      return false;
    }

    // Can see an unclaimed live chat.
    if (!ticketQuery.data.data.assignee) {
      return true;
    }

    // Can only see a live chat you have claimed in this view.
    // This is only false if someone hard refreshed the /live-chat/:id route
    // after someone has claimed the ticket.
    return ticketQuery.data.data.assignee.id === agent.id;
  }, [ticketQuery, agent.id]);

  const onForward = async () => {
    await queryClient.invalidateQueries('tickets/ubidesk');
    history.push(`/live-chat`);
  };

  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1400px)').matches
  );

  useEffect(() => {
    window.matchMedia('(max-width: 1400px)').addEventListener('change', e => {
      setMatches(e.matches);
    });
  }, []);

  return (
    <Flex fat>
      <TicketMainPanel>
        {ticketQuery.isLoading && (
          <Flex pad fat>
            <Placeholder />
          </Flex>
        )}
        {ticketQuery.isSuccess && !canSeeLiveChat && (
          <Redirect
            to={{
              pathname: '/live-chat',
              state: {
                notification: {
                  type: 'information',
                  message: 'That ticket has already been claimed.'
                }
              }
            }}
          />
        )}
        {ticketQuery.isSuccess && canSeeLiveChat && (
          <Flex col fat>
            <Flex col fat background="white" borderBottom pb>
              {!ticketQuery.data.data.is_active &&
                ticketQuery.data.data.status === 'in_progress' && (
                  <ModalContainer>
                    <ModalContent>
                      <ModalHeader>
                        Chatbot user left the conversation
                      </ModalHeader>
                      <ModalBody>
                        The chatbot user manually ended the live chat. The
                        ticket can now either be deleted or resolved.
                      </ModalBody>
                      <ModalFooter>
                        <Flex fat xSpace right>
                          <PermissionFilter can="delete tickets">
                            <Button
                              variant="secondary"
                              icon="xCircle"
                              colour="danger"
                              onClick={handleDeleteAfterSubscriberHasLeft}>
                              Delete
                            </Button>
                          </PermissionFilter>
                          <PermissionFilter can="edit tickets">
                            <ResolveButton ticket={ticketQuery.data.data} />
                          </PermissionFilter>
                        </Flex>
                      </ModalFooter>
                    </ModalContent>
                  </ModalContainer>
                )}
              <Flex pl pr pt col>
                <Flex fat between>
                  <Flex mbSm xSpaceSm center>
                    <Icon
                      width="32px"
                      size="32px"
                      height="32px"
                      type="userCircle"
                    />
                    <Heading2>
                      <Flex xSpace center>
                        <Span>User #{ticketQuery.data.data.subscriber.id}</Span>
                        {ticketQuery.data.data.status === 'in_progress' ? (
                          <Tag background="positive" colour="positive">
                            Claimed
                          </Tag>
                        ) : (
                          <Tag background="warning" colour="warning">
                            Pending
                          </Tag>
                        )}
                      </Flex>
                    </Heading2>
                  </Flex>
                  <Flex xSpace wrap>
                    {showDelete(ticketQuery.data.data) && (
                      <PermissionFilter can="delete tickets">
                        <Button
                          variant="secondary"
                          icon="xCircle"
                          colour="danger"
                          onClick={handleDelete}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    )}
                    {showClaim(ticketQuery.data.data) && (
                      <PermissionFilter can="edit tickets">
                        <Button
                          variant="primary"
                          icon="plus"
                          onClick={handleClaim}>
                          Claim
                        </Button>
                      </PermissionFilter>
                    )}
                    {showUnclaim(ticketQuery.data.data) && (
                      <PermissionFilter can="edit tickets">
                        <Button
                          variant="secondary"
                          icon="xCircle"
                          colour="danger"
                          onClick={handleUnclaim}>
                          Unclaim
                        </Button>
                      </PermissionFilter>
                    )}
                    {showForward(ticketQuery.data.data) && (
                      <PermissionsFilter can={['edit tickets', 'view agents']}>
                        <ForwardTicketButton
                          id={ticketQuery.data.data.id}
                          onSuccess={onForward}>
                          Forward
                        </ForwardTicketButton>
                      </PermissionsFilter>
                    )}
                    {showResolve(ticketQuery.data.data) && (
                      <PermissionFilter can="edit tickets">
                        <ResolveButton ticket={ticketQuery.data.data} />
                      </PermissionFilter>
                    )}
                  </Flex>
                </Flex>
                <Flex center xSpace>
                  <AnimateSharedLayout>
                    <TabButton
                      active={tab === 'CONVERSATION'}
                      onClick={() => setTab('CONVERSATION')}>
                      Conversation
                    </TabButton>
                    {matches && (
                      <TabButton
                        active={tab === 'SUBSCRIBER'}
                        onClick={() => setTab('SUBSCRIBER')}>
                        User
                      </TabButton>
                    )}
                    <TabButton
                      active={tab === 'TICKET'}
                      onClick={() => setTab('TICKET')}>
                      Ticket
                    </TabButton>
                  </AnimateSharedLayout>
                </Flex>
              </Flex>
            </Flex>
            {tab === 'CONVERSATION' && (
              <Conversation ticket={ticketQuery.data.data} />
            )}
            {!matches && tab === 'SUBSCRIBER' && setTab('CONVERSATION')}
            {matches && tab === 'SUBSCRIBER' && (
              <Flex pad fat tall>
                <SubscriberDetails
                  subscriber={ticketQuery.data.data.subscriber}
                  ticket={ticketQuery.data.data}
                />
              </Flex>
            )}
            {tab === 'TICKET' && <Ticket ticket={ticketQuery.data.data} />}
          </Flex>
        )}
      </TicketMainPanel>
      {ticketQuery.isSuccess && canSeeLiveChat && (
        <TicketSubscriberPanel>
          {ticketQuery.data.data.subscriber && (
            <TicketSubscriberDetails
              subscriber={ticketQuery.data.data.subscriber}
              ticket={ticketQuery.data.data}
            />
          )}
        </TicketSubscriberPanel>
      )}
    </Flex>
  );
};

LiveChat.propTypes = {
  agent: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default LiveChat;
